import React, { useContext } from 'react';
import DataContext from '../context/dataContext';
import ReactPlayer from 'react-player';

const Quiz = () => {
    const { showQuiz, question, quizs, checkAnswer, correctAnswer,
            selectedAnswer,questionIndex, nextQuestion, showTheResult }  = useContext(DataContext);

            function RenderWithLineBreaks({ text }) {
                // Séparer le texte en segments sur les retours à la ligne
                const segments = text.split('\n').map((segment, index, array) =>
                  // Rendre chaque segment avec un <br /> après, sauf pour le dernier segment
                  index === array.length - 1 ? segment : <React.Fragment key={index}>{segment}<br /></React.Fragment>
                );
              
                return <>{segments}</>;
              }

    return (
        <section className="bg-dark text-white overflow-auto
        " style={{ display: `${showQuiz ? 'block' : 'none'}` }}>
            <div className="container max-height">
                <div className="row vh-100 align-items-center justify-content-center">
                    <div className="col-lg-8">
                        <div className="card p-4" style={{ background: '#3d3d3d', borderColor: '#646464' }}>
                            <div className="d-flex justify-content-between gap-md-3">
                                <h5 className='mb-2 fs-normal lh-base'>{question?.question}</h5>
                                <h5 style={{ color: '#60d600', width: '100px', textAlign: 'right' }}>{quizs.indexOf(question) + 1} / {quizs?.length}</h5>
                            </div>
                            <div>
                                {
                                    question?.options?.map((item, index) => <button
                                        key={index}
                                        className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${correctAnswer === item && 'bg-success'}`}
                                        onClick={(event) => checkAnswer(event, item)}
                                    >
                                        {item}
                                    </button>)
                                }
                            </div>
                            { selectedAnswer && question?.explanation && (
                            <div className="d-flex flex-column gap-2 mt-3">
                                <h5 className='mb-2 fs-normal lh-base'>Explication :</h5>
                                <p className=' w-100 text-start text-white py-2 px-3 rounded btn-dark'>{RenderWithLineBreaks({text: question?.explanation})}</p>
                            {question?.videoMedia && 
                                <ReactPlayer url={question?.videoMedia} controls={true} width='100%' height='453px'/>
                            }
                            {
                                question?.imageMedia?.map((item, index) => <img key={item.link} src={item.link} alt="media" className='w-100 mt-3' />)
                            }
                            </div>
                            )}

                            {
                                (questionIndex + 1) !== quizs.length ?
                                    <button className='btn py-2 w-100 mt-3 bg-primary text-light fw-bold' onClick={nextQuestion} disabled={!selectedAnswer}>Question suivante</button>
                                    :
                                    <button className='btn py-2 w-100 mt-3 bg-primary text-light fw-bold' onClick={showTheResult} disabled={!selectedAnswer}>Afficher le résultat</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Quiz;