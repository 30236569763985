import React, { useContext } from 'react';
import DataContext from '../context/dataContext';

const Start = () => {
    const {startQuiz, showStart} = useContext(DataContext);
    return (
        <section className='text-white text-center bg-dark' style={{ display: `${showStart ? 'block' : 'none'}` }}>
            <div className="container">
                <div className="row vh-100 align-items-center justify-content-center">
                    <div className="col-lg-6">
                        <h1 className='fw-bold mb-4'>Le CES (Consumer Electronics Show) est un salon annuel qui se tient à Las Vegas et présente les dernières innovations technologiques dans les domaines de l'électronique grand public, de l'automobile et de l'industrie.</h1>
                    </div>
                    <div className="col-lg-6">
                        <h1 className='fw-bold mb-4'>CES 2024 Quiz</h1>
                        <button onClick={startQuiz} className="btn px-4 py-2 bg-light text-dark fw-bold">Débuter le quiz</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Start;